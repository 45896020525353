export function trackEvent(
  eventName: string,
  eventParams: Record<string, any> = {},
) {
  const { gtag } = useGtag()
  if (gtag) {
    gtag('event', eventName, eventParams)
  }
  else {
    console.warn('Google Analytics not initialized')
  }
}
