import { UserRolesEnum } from '~/utils/enums/userRoles'

export default defineNuxtRouteMiddleware(async (to) => {
  const { $acquireTokenSilent } = useNuxtApp()
  const accessToken = (await $acquireTokenSilent())?.accessToken
  const { attemptedPath, userRoles } = storeToRefs(useAuthStore())

  if (to.path !== '/' && !accessToken) {
    attemptedPath.value = to.fullPath
    return navigateTo('/')
  }
  else if (
    accessToken
    && ((!userRoles.value.includes(UserRolesEnum.GLOBAL_ADMIN)
    && to.path.includes('/admin'))
    || to.path === '/')
  ) {
    return navigateTo('/projects')
  }
})
