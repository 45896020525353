import { type AccountInfo, PublicClientApplication } from '@azure/msal-browser'

export default defineNuxtPlugin(async () => {
  const appConfig = useAppConfig()
  const { currentUser, authError, userContext, userRoles, showAdminInterface }
    = storeToRefs(useAuthStore())
  const config = {
    auth: {
      clientId: appConfig.AZURE_CLIENT_ID,
      tenantId: appConfig.AZURE_TENANT_ID,
      authority: `https://login.microsoftonline.com/organizations/`,
    } as AuthConfig,
  }

  const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  }

  const getUserData = async () => {
    const userData = await fetch(graphConfig.graphMeEndpoint, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
      },
    }).then(response => response.json())

    return userData
  }

  const fetchUserContext = async () => {
    const userContext = (await $fetch(`${appConfig.DEV_ENDPOINT}/users/me`, {
      headers: {
        method: 'GET',
        mode: 'no-cors',
        Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
      },
    })) as UserContext

    return userContext
  }

  const fetchUserRoles = async () => {
    const result = (await $fetch(`${appConfig.DEV_ENDPOINT}/users/me/roles`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
      },
    })) as UserRole[]
    return result.map(role => role.name)
  }

  const msal = new PublicClientApplication(config)
  msal.initialize()

  const login = async () => {
    localStorage.clear()
    sessionStorage.clear()
    try {
      const loginResponse = await msal.loginPopup()
      const loginToken = loginResponse.accessToken
      sessionStorage.setItem('authToken', loginToken)
      currentUser.value = await getUserData()
      userContext.value = await fetchUserContext()
      userRoles.value = await fetchUserRoles()
      return loginResponse
    }
    catch (err) {
      if (err)
        authError.value = 'Login failed. Please try again.'
    }
  }

  const acquireTokenSilent = async () => {
    try {
      const account = msal.getAllAccounts()
      if (account.length > 0) {
        const tokenRequest = {
          scopes: ['user.read'],
          account: account[0],
        }
        const tokenResponse = await msal.acquireTokenSilent(tokenRequest)
        sessionStorage.setItem('authToken', tokenResponse.accessToken)
        return tokenResponse
      }
      else {
        navigateTo('/')
      }
    }
    catch (error) {
      if (error) {
        navigateTo('/')
      }
      else {
        authError.value = 'Error acquiring token'
        navigateTo('/')
      }
    }
  }

  const getAccounts = () => {
    return msal.getAllAccounts()
  }

  const logout = async () => {
    try {
      const token = await acquireTokenSilent()
      const homeAccountId = token?.account?.homeAccountId
      const currentAccount = msal.getAccount(
        homeAccountId as unknown as AccountInfo,
      )
      sessionStorage.removeItem('authToken')
      showAdminInterface.value = false
      await msal.logoutRedirect({ account: currentAccount })
    }
    catch (err) {
      if (err)
        authError.value = 'Error logging out'
    }
  }

  return {
    provide: {
      login,
      acquireTokenSilent,
      getAccounts,
      logout,
    },
  }
})
