
// @ts-nocheck
import locale__working_dir_locales_en_0_json from "../locales/en-0.json";
import locale__working_dir_locales_cs_CZ_json from "../locales/cs-CZ.json";
import locale__working_dir_locales_da_DK_json from "../locales/da-DK.json";
import locale__working_dir_locales_nl_NL_json from "../locales/nl-NL.json";
import locale__working_dir_locales_en_GB_json from "../locales/en-GB.json";
import locale__working_dir_locales_fi_FI_json from "../locales/fi-FI.json";
import locale__working_dir_locales_fr_FR_json from "../locales/fr-FR.json";
import locale__working_dir_locales_de_AT_json from "../locales/de-AT.json";
import locale__working_dir_locales_de_CH_json from "../locales/de-CH.json";
import locale__working_dir_locales_it_IT_json from "../locales/it-IT.json";
import locale__working_dir_locales_no_NO_json from "../locales/no-NO.json";
import locale__working_dir_locales_pl_PL_json from "../locales/pl-PL.json";
import locale__working_dir_locales_pt_PT_json from "../locales/pt-PT.json";
import locale__working_dir_locales_es_ES_json from "../locales/es-ES.json";
import locale__working_dir_locales_sv_SE_json from "../locales/sv-SE.json";
import locale__working_dir_locales_de_DE_json from "../locales/de-DE.json";
import locale__working_dir_locales_tr_TR_json from "../locales/tr-TR.json";


export const localeCodes =  [
  "en",
  "cs",
  "da",
  "nl",
  "en-gb",
  "fi",
  "fr",
  "de-at",
  "de-ch",
  "it",
  "no",
  "pl",
  "pt",
  "es",
  "sv",
  "de",
  "tr"
]

export const localeLoaders = {
  "en": [{ key: "../locales/en-0.json", load: () => Promise.resolve(locale__working_dir_locales_en_0_json), cache: true }],
  "cs": [{ key: "../locales/cs-CZ.json", load: () => Promise.resolve(locale__working_dir_locales_cs_CZ_json), cache: true }],
  "da": [{ key: "../locales/da-DK.json", load: () => Promise.resolve(locale__working_dir_locales_da_DK_json), cache: true }],
  "nl": [{ key: "../locales/nl-NL.json", load: () => Promise.resolve(locale__working_dir_locales_nl_NL_json), cache: true }],
  "en-gb": [{ key: "../locales/en-GB.json", load: () => Promise.resolve(locale__working_dir_locales_en_GB_json), cache: true }],
  "fi": [{ key: "../locales/fi-FI.json", load: () => Promise.resolve(locale__working_dir_locales_fi_FI_json), cache: true }],
  "fr": [{ key: "../locales/fr-FR.json", load: () => Promise.resolve(locale__working_dir_locales_fr_FR_json), cache: true }],
  "de-at": [{ key: "../locales/de-AT.json", load: () => Promise.resolve(locale__working_dir_locales_de_AT_json), cache: true }],
  "de-ch": [{ key: "../locales/de-CH.json", load: () => Promise.resolve(locale__working_dir_locales_de_CH_json), cache: true }],
  "it": [{ key: "../locales/it-IT.json", load: () => Promise.resolve(locale__working_dir_locales_it_IT_json), cache: true }],
  "no": [{ key: "../locales/no-NO.json", load: () => Promise.resolve(locale__working_dir_locales_no_NO_json), cache: true }],
  "pl": [{ key: "../locales/pl-PL.json", load: () => Promise.resolve(locale__working_dir_locales_pl_PL_json), cache: true }],
  "pt": [{ key: "../locales/pt-PT.json", load: () => Promise.resolve(locale__working_dir_locales_pt_PT_json), cache: true }],
  "es": [{ key: "../locales/es-ES.json", load: () => Promise.resolve(locale__working_dir_locales_es_ES_json), cache: true }],
  "sv": [{ key: "../locales/sv-SE.json", load: () => Promise.resolve(locale__working_dir_locales_sv_SE_json), cache: true }],
  "de": [{ key: "../locales/de-DE.json", load: () => Promise.resolve(locale__working_dir_locales_de_DE_json), cache: true }],
  "tr": [{ key: "../locales/tr-TR.json", load: () => Promise.resolve(locale__working_dir_locales_tr_TR_json), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "iso": "en-US",
      "name": "English",
      "files": [
        "locales/en-0.json"
      ]
    },
    {
      "code": "cs",
      "iso": "cs-CZ",
      "name": "Čeština",
      "files": [
        "locales/cs-CZ.json"
      ]
    },
    {
      "code": "da",
      "iso": "da-DK",
      "name": "Dansk",
      "files": [
        "locales/da-DK.json"
      ]
    },
    {
      "code": "nl",
      "iso": "nl-NL",
      "name": "Nederlands",
      "files": [
        "locales/nl-NL.json"
      ]
    },
    {
      "code": "en-gb",
      "iso": "en-GB",
      "name": "English (UK)",
      "files": [
        "locales/en-GB.json"
      ]
    },
    {
      "code": "fi",
      "iso": "fi-FI",
      "name": "Suomi",
      "files": [
        "locales/fi-FI.json"
      ]
    },
    {
      "code": "fr",
      "iso": "fr-FR",
      "name": "Français",
      "files": [
        "locales/fr-FR.json"
      ]
    },
    {
      "code": "de-at",
      "iso": "de-AT",
      "name": "Deutsch (Österreich)",
      "files": [
        "locales/de-AT.json"
      ]
    },
    {
      "code": "de-ch",
      "iso": "de-CH",
      "name": "Deutsch (Schweiz)",
      "files": [
        "locales/de-CH.json"
      ]
    },
    {
      "code": "it",
      "iso": "it-IT",
      "name": "Italiano",
      "files": [
        "locales/it-IT.json"
      ]
    },
    {
      "code": "no",
      "iso": "no-NO",
      "name": "Norsk",
      "files": [
        "locales/no-NO.json"
      ]
    },
    {
      "code": "pl",
      "iso": "pl-PL",
      "name": "Polski",
      "files": [
        "locales/pl-PL.json"
      ]
    },
    {
      "code": "pt",
      "iso": "pt-PT",
      "name": "Português",
      "files": [
        "locales/pt-PT.json"
      ]
    },
    {
      "code": "es",
      "iso": "es-ES",
      "name": "Español",
      "files": [
        "locales/es-ES.json"
      ]
    },
    {
      "code": "sv",
      "iso": "sv-SE",
      "name": "Svenska",
      "files": [
        "locales/sv-SE.json"
      ]
    },
    {
      "code": "de",
      "iso": "de-DE",
      "name": "Deutsch",
      "files": [
        "locales/de-DE.json"
      ]
    },
    {
      "code": "tr",
      "iso": "tr-TR",
      "name": "Türkçe",
      "files": [
        "locales/tr-TR.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": false,
  "langDir": "locales/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "iso": "en-US",
    "name": "English",
    "files": [
      {
        "path": "locales/en-0.json"
      }
    ]
  },
  {
    "code": "cs",
    "iso": "cs-CZ",
    "name": "Čeština",
    "files": [
      {
        "path": "locales/cs-CZ.json"
      }
    ]
  },
  {
    "code": "da",
    "iso": "da-DK",
    "name": "Dansk",
    "files": [
      {
        "path": "locales/da-DK.json"
      }
    ]
  },
  {
    "code": "nl",
    "iso": "nl-NL",
    "name": "Nederlands",
    "files": [
      {
        "path": "locales/nl-NL.json"
      }
    ]
  },
  {
    "code": "en-gb",
    "iso": "en-GB",
    "name": "English (UK)",
    "files": [
      {
        "path": "locales/en-GB.json"
      }
    ]
  },
  {
    "code": "fi",
    "iso": "fi-FI",
    "name": "Suomi",
    "files": [
      {
        "path": "locales/fi-FI.json"
      }
    ]
  },
  {
    "code": "fr",
    "iso": "fr-FR",
    "name": "Français",
    "files": [
      {
        "path": "locales/fr-FR.json"
      }
    ]
  },
  {
    "code": "de-at",
    "iso": "de-AT",
    "name": "Deutsch (Österreich)",
    "files": [
      {
        "path": "locales/de-AT.json"
      }
    ]
  },
  {
    "code": "de-ch",
    "iso": "de-CH",
    "name": "Deutsch (Schweiz)",
    "files": [
      {
        "path": "locales/de-CH.json"
      }
    ]
  },
  {
    "code": "it",
    "iso": "it-IT",
    "name": "Italiano",
    "files": [
      {
        "path": "locales/it-IT.json"
      }
    ]
  },
  {
    "code": "no",
    "iso": "no-NO",
    "name": "Norsk",
    "files": [
      {
        "path": "locales/no-NO.json"
      }
    ]
  },
  {
    "code": "pl",
    "iso": "pl-PL",
    "name": "Polski",
    "files": [
      {
        "path": "locales/pl-PL.json"
      }
    ]
  },
  {
    "code": "pt",
    "iso": "pt-PT",
    "name": "Português",
    "files": [
      {
        "path": "locales/pt-PT.json"
      }
    ]
  },
  {
    "code": "es",
    "iso": "es-ES",
    "name": "Español",
    "files": [
      {
        "path": "locales/es-ES.json"
      }
    ]
  },
  {
    "code": "sv",
    "iso": "sv-SE",
    "name": "Svenska",
    "files": [
      {
        "path": "locales/sv-SE.json"
      }
    ]
  },
  {
    "code": "de",
    "iso": "de-DE",
    "name": "Deutsch",
    "files": [
      {
        "path": "locales/de-DE.json"
      }
    ]
  },
  {
    "code": "tr",
    "iso": "tr-TR",
    "name": "Türkçe",
    "files": [
      {
        "path": "locales/tr-TR.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
