import type { FetchError } from 'ofetch'

export const useProjectStore = defineStore('project', () => {
  const appConfig = useAppConfig()
  const { $acquireTokenSilent } = useNuxtApp()
  const opportunities = ref<Opportunity[]>([])
  const accountSites = ref<AccountSite[]>([])
  const riskValueChains = ref<RiskValueChain[]>([])
  const projectTitle = ref('')
  const status = ref('')

  const tempCoverImage = ref<File | null>(null)

  const projects = ref<Project[]>([])
  const selectedArea = ref<Area | null>(null)
  const selectedRisk = ref<Risk | null>(null)
  const selectedProject = ref<Project | null>(null)
  const selectedAccountSite = ref<AccountSite | null>(null)
  const selectedRiskValueChain = ref<RiskValueChain | null>(null)
  const selectedScenario = ref<Scenario | null>(null)
  const selectedOpportunity = ref<Opportunity | null>(null)
  const selectedOpportunityContactPerson = ref<ContactPerson | null>(null)
  const selectedSortBy = ref<SortByOptions>()

  const suggestedAreas = ref<AreaType[]>([])
  const suggestedRisks = ref<RiskType[]>([])
  const allRiskTypes = ref<RiskType[]>([])
  const suggestedSolutionTypes = ref<SolutionType[]>([])

  const draftProjects = ref<Project[]>([])
  const inProgressProjects = ref<Project[]>([])
  const completedProjects = ref<Project[]>([])

  const pending = ref(false)
  const error = ref<string | null>(null)
  const customError = ref<string | null>(null)
  const customSuccessMessage = ref<string | null>(null)
  const showNotification = ref(false)
  const shouldRefetch = ref(false)

  const scenarioImages = ref(new Map<string, File[]>())

  const allSolutionTypes = ref<SolutionType[]>([])

  // caching
  const cache = ref(new Map<string, any>())

  function setImage(file: File) {
    tempCoverImage.value = file
  }

  function resetImage() {
    tempCoverImage.value = null
  }

  function resetNewProject() {
    projectTitle.value = ''
    status.value = ''
    pending.value = false
    selectedOpportunity.value = null
    selectedAccountSite.value = null
    selectedRiskValueChain.value = null
    opportunities.value = []
    accountSites.value = []
    riskValueChains.value = []
  }

  function reset() {
    projectTitle.value = ''
    status.value = ''
    pending.value = false
    selectedOpportunity.value = null
    selectedAccountSite.value = null
    selectedRiskValueChain.value = null
    error.value = null
    opportunities.value = []
    accountSites.value = []
    riskValueChains.value = []
    projects.value = []
    accountSites.value = []
    riskValueChains.value = []
  }

  async function fetchRequest(fetchEndpoint: string, fetching: Ref<boolean>) {
    fetching.value = true
    const authToken = (await $acquireTokenSilent())?.accessToken
    const finalizedEndpoint = `${appConfig.DEV_ENDPOINT}/${fetchEndpoint}`
    error.value = null

    try {
      const data = await $fetch(finalizedEndpoint, {
        headers: {
          mode: 'no-cors',
          method: 'GET',
          Authorization: `Bearer ${authToken}`,
        },
      })

      return data
    }
    catch (err) {
      const statusCode = (err as FetchError).statusCode ?? 0
      if (statusCode >= 400 && statusCode < 500) {
        return null
      }
      else {
        error.value = (err as Error).message
        showNotification.value = true
      }
    }
    finally {
      fetching.value = false
    }
  }

  async function postRequest(
    endpoint: string,
    postRequestData: any,
    posting: Ref<boolean>,
  ) {
    posting.value = true
    const authToken = (await $acquireTokenSilent())?.accessToken
    const finalizedEndpoint = `${appConfig.DEV_ENDPOINT}/${endpoint}`
    error.value = null

    try {
      const data = await $fetch(finalizedEndpoint, {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'admin-api-key': appConfig.ADVISOR_ADMIN_API_KEY,
        } as HeadersInit,
        method: 'POST',
        body:
          postRequestData instanceof FormData
            ? postRequestData
            : JSON.stringify(postRequestData),
      })

      return data
    }
    catch (err) {
      error.value = (err as Error).message
    }
    finally {
      posting.value = false
    }
  }

  async function patchRequest(
    endpoint: string,
    body: any,
    patching: Ref<boolean>,
  ) {
    patching.value = true
    const authToken = (await $acquireTokenSilent())?.accessToken
    error.value = null

    try {
      const finalizedEndpoint = `${appConfig.DEV_ENDPOINT}/${endpoint}`
      const data = await $fetch(finalizedEndpoint, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        method: 'PATCH',
        body: JSON.stringify(body),
      })

      return data
    }
    catch (err) {
      error.value = (err as Error).message
    }
    finally {
      patching.value = false
    }
  }

  async function deleteRequest(endpoint: string, deleting: Ref<boolean>) {
    deleting.value = true
    const authToken = (await $acquireTokenSilent())?.accessToken
    const finalizedEndpoint = `${appConfig.DEV_ENDPOINT}/${endpoint}`
    error.value = null

    try {
      const data = await $fetch(finalizedEndpoint, {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'admin-api-key': appConfig.ADVISOR_ADMIN_API_KEY,
        } as HeadersInit,
        method: 'DELETE',
      })

      return data
    }
    catch (err) {
      error.value = (err as Error).message
      showNotification.value = true
    }
    finally {
      deleting.value = false
    }
  }

  async function fetchProjects(fetching: Ref<boolean>, id?: string) {
    if (projects.value.length > 0 && !id)
      return

    const fetchEndpoint = `projects/${id ?? ''}`
    const data = await fetchRequest(fetchEndpoint, fetching)

    if (id) {
      const fetchedProject = Array.isArray(data)
        ? data[0]
        : (data as Project | null)
      if (fetchedProject?.image) {
        fetchedProject.image = await fetchProjectCoverImage(id, fetching)
      }
      // if load a single project, wait until the image is loaded
      selectedProject.value = fetchedProject
    }
    else {
      // if loading projects, not wait until the cover image is loaded
      projects.value = (data ?? []) as Project[]
      sortProjects()
      projects.value.forEach((project, index) => {
        if (project.image) {
          const projectId = project.id
          fetchProjectCoverImage(projectId).then((img) => {
            projects.value[index].image = img
          })
        }
      })
    }
  }

  async function fetchProjectCoverImage(
    projectId: string,
    fetching?: Ref<boolean>,
  ) {
    const endpoint = `projects/${projectId}/image`
    const blob = (await fetchRequest(endpoint, fetching ?? ref(false))) as Blob
    return blob ? URL.createObjectURL(blob) : undefined
  }

  async function fetchOpportunities(fetching: Ref<boolean>, id?: string) {
    if (opportunities.value.length > 0 && !id)
      return

    const fetchEndpoint = `opportunities/${id ?? ''}`
    const data = await fetchRequest(fetchEndpoint, fetching)

    if (id) {
      selectedOpportunity.value = data as Opportunity
    }
    else {
      opportunities.value = ((data ?? []) as Opportunity[]).filter(
        opportunity => opportunity.account,
      )
    }
  }

  async function fetchContactPerson(fetching: Ref<boolean>, id?: string) {
    if (!selectedOpportunity.value && !id)
      return

    const fetchEndpoint = `opportunities/${id ?? selectedOpportunity.value?.id}/contact`
    const data = await fetchRequest(fetchEndpoint, fetching)

    selectedOpportunityContactPerson.value = data as ContactPerson
  }

  async function fetchAccountAccountSites(fetching: Ref<boolean>, id?: string) {
    if (accountSites.value?.length > 0 && !id)
      return
    if (!selectedOpportunity.value && !id)
      return

    const fetchEndpoint = `opportunities/${id ?? selectedOpportunity.value?.id}/account-site-options`
    const data = await fetchRequest(fetchEndpoint, fetching)

    if (id)
      selectedAccountSite.value = data as AccountSite
    else accountSites.value = data as AccountSite[]
  }

  async function fetchRiskValueChains(fetching: Ref<boolean>, id?: string) {
    if (riskValueChains.value.length > 0 && !id) {
      return
    }
    const fetchEndpoint = `risk-value-chains/${id ?? ''}`
    const data = await fetchRequest(fetchEndpoint, fetching)
    if (id)
      selectedRiskValueChain.value = data as RiskValueChain
    else riskValueChains.value = data as RiskValueChain[]
  }

  async function createProject(posting: Ref<boolean>) {
    const authToken = (await $acquireTokenSilent())?.accessToken
    const postRequestBody = {
      name: projectTitle.value,
      status: status.value,
      opportunityId: selectedOpportunity.value?.id,
      riskValueChainId: selectedRiskValueChain.value?.id,
    }
    posting.value = true

    try {
      const data = await $fetch<Project>(`${appConfig.DEV_ENDPOINT}/projects`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        method: 'POST',
        body: JSON.stringify(postRequestBody),
      })
      if (data) {
        // refetch again is because the response of POST /projects lacks opportunity field
        await fetchProjects(posting, data.id)
        if (selectedProject.value) {
          projects.value.push(selectedProject.value)
          sortProjects()
        }

        // upload the picture
        await uploadPicture(data.id, posting)

        showNotification.value = true
        return selectedProject.value
      }
    }
    catch (err) {
      error.value = (err as Error).message
      showNotification.value = true
    }
    finally {
      posting.value = false
    }
  }

  // Function to upload a picture
  async function uploadPicture(projectId: string, posting: Ref<boolean>) {
    // Check if there is a picture to upload
    if (tempCoverImage.value) {
      const endpoint = `projects/${projectId}/image`
      const formData = new FormData()
      formData.append('image', tempCoverImage.value)
      await postRequest(endpoint, formData, posting)

      // Update the selected project's image with the new URL
      if (selectedProject.value)
        selectedProject.value.image = URL.createObjectURL(tempCoverImage.value)
    }
  }

  async function updateProject(
    patching: Ref<boolean>,
    patchRequest: ProjectPatchRequest,
    id?: string,
  ) {
    if (!selectedProject.value && !id)
      return

    const authToken = (await $acquireTokenSilent())?.accessToken
    const fetchEndpoint = `${appConfig.DEV_ENDPOINT}/projects/${id ?? selectedProject.value?.id}`

    patching.value = true

    try {
      const data = await $fetch<Project>(fetchEndpoint, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        method: 'PATCH',
        body: JSON.stringify(patchRequest),
      })
      if (data && selectedProject.value) {
        selectedProject.value.name = patchRequest.name ?? data.name
        selectedProject.value.status = patchRequest.status ?? data.status
        selectedProject.value.updatedAt = data.updatedAt
        // upload the picture
        await uploadPicture(data.id, patching)
        sortProjects()
      }
    }
    catch (err) {
      error.value = (err as Error).message
    }
    finally {
      showNotification.value = true
      patching.value = false
    }
  }

  async function sortProjects() {
    projects.value.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
  }

  async function fetchSuggestedAreas(fetching: Ref<boolean>, id?: string) {
    if (!selectedProject.value && !id)
      return

    const fetchEndpoint = `projects/${id ?? selectedProject.value?.id}/suggested-area-types`
    if (cache.value.has(fetchEndpoint)) {
      suggestedAreas.value = [
        ...cache.value.get(fetchEndpoint),
      ] as SuggestedArea[]
      return
    }
    const data = (await fetchRequest(fetchEndpoint, fetching)) as
      | AreaType[]
      | null
    if (data) {
      cache.value.set(fetchEndpoint, JSON.parse(JSON.stringify(data)))
    }
    suggestedAreas.value = data as SuggestedArea[]
  }

  async function fetchSuggestedRiskTypes(
    fetching: Ref<boolean>,
    id?: string | number,
  ) {
    // Risk types are the risks associated with the area selected aka to specific Risk value chain
    if (!selectedProject.value && !id)
      return

    const fetchEndpoint = `projects/${selectedProject.value?.id}/suggested-risk-types${id ? `?areaTypeId=${id}` : ''}`
    if (cache.value.has(fetchEndpoint)) {
      const cachedRisks = cache.value.get(fetchEndpoint)
      suggestedRisks.value = [...cachedRisks]
      return [...cachedRisks]
    }
    const data = await fetchRequest(fetchEndpoint, fetching)
    suggestedRisks.value = (data as RiskType[]) ?? []
    if (data)
      cache.value.set(fetchEndpoint, data)
    return data
  }

  async function fetchAllRiskTypes(fetching: Ref<boolean>) {
    const fetchEndpoint = 'risk-types'

    const data = await fetchRequest(fetchEndpoint, fetching)
    allRiskTypes.value = (data as RiskType[]) ?? []
    return data
  }

  async function fetchSuggestedSolutionTypes(
    fetching: Ref<boolean>,
    id?: string,
    refetch?: boolean,
  ) {
    if (
      (!selectedProject.value && !id)
      || !selectedArea.value
      || !selectedRisk.value
      || !selectedScenario.value
      || !selectedScenario.value.id
    ) {
      return
    }

    const scenarioId = selectedScenario.value.id
    const fetchEndpoint = `projects/${id ?? selectedProject.value?.id}/areas/${selectedArea.value?.id}/risks/${selectedRisk.value?.id}/scenarios/${scenarioId}/suggested-solution-types`
    if (cache.value.has(fetchEndpoint) && !refetch) {
      suggestedSolutionTypes.value = [...cache.value.get(fetchEndpoint)]
      return
    }
    const data = ((await fetchRequest(fetchEndpoint, fetching))
      ?? []) as SolutionType[]
    suggestedSolutionTypes.value = data
    cache.value.set(fetchEndpoint, [...data])
  }

  async function deleteProject(deleting: Ref<boolean>, projectId: string) {
    trackEvent('project_delete_button_clicked', {
      place: 'project page',
      project_id: projectId,
      project_name: selectedProject.value?.name,
    })
    if (!projectId) {
      return
    }

    const endpoint = `projects/${projectId}`
    if (await deleteRequest(endpoint, deleting)) {
      projects.value = projects.value.filter(
        project => project.id !== projectId,
      )
      customSuccessMessage.value = 'project_deleted_successfully'
      showNotification.value = true
      navigateTo('/projects')
    }
  }

  async function deleteSolution(deleting: Ref<boolean>, id: string) {
    if (
      !selectedProject.value
      || !selectedArea.value
      || !selectedRisk.value
      || !selectedScenario.value
      || !id
    ) {
      return
    }
    const endpoint = `projects/${selectedProject.value?.id}/areas/${selectedArea.value?.id}/risks/${selectedRisk.value?.id}/scenarios/${selectedScenario.value?.id}/solutions/${id}`
    return await deleteRequest(endpoint, deleting)
  }

  async function createBulkSolution(posting: Ref<boolean>, body: any) {
    if (
      !selectedProject.value
      || !selectedArea.value
      || !selectedRisk.value
      || !selectedScenario.value
      || !body
    ) {
      return
    }
    const endpoint = `projects/${selectedProject.value?.id}/areas/${selectedArea.value?.id}/risks/${selectedRisk.value?.id}/scenarios/${selectedScenario.value?.id}/create-bulk-solutions`
    const data = await postRequest(endpoint, body, posting)

    showNotification.value = true
    return data
  }

  async function createAreasAndRisks(
    posting: Ref<boolean>,
    body: Area[],
    id?: any,
  ) {
    if (!selectedProject.value || !body)
      return

    const areaTypes: AreasPostRequest = {
      areaTypeIds: [],
    }

    if (body.length === 0 || !body) {
      return
    }
    // create non-custom areas
    const existingAreaTypeIds = (selectedProject.value.areas
      ?.map(area => area.areaType?.id)
      .filter(Boolean) ?? []) as string[]

    // check whether the areaType already exists inside selectedProject. If not exists, create the area
    areaTypes.areaTypeIds = body
      .filter(area => !area.isCustom)
      .map(area => area.areaType?.id)
      .filter(Boolean)
      .filter(id => !existingAreaTypeIds.includes(id as string)) as string[]

    if (areaTypes.areaTypeIds.length > 0) {
      const postAreaTypesEndpoint = `projects/${id ?? selectedProject.value?.id}/create-bulk-areas`
      selectedProject.value = (await postRequest(
        postAreaTypesEndpoint,
        areaTypes,
        posting,
      )) as Project
    }

    await Promise.all(
      body
        .filter(area => !area.isCustom)
        .map(async (area) => {
          if (!(area.risks && area.risks.length > 0))
            return

          const updatedArea = selectedProject.value?.areas?.find(
            (a: Area) => a.areaType?.id === area.areaType?.id,
          )

          const postRisksTypesEndpoint = `projects/${selectedProject.value?.id}/areas/${updatedArea?.id}/create-bulk-risks`

          const riskTypes: RisksPostRequest = {
            riskTypeIds: [],
          }
          const existingRiskTypeIds = updatedArea?.risks?.map(
            risk => risk.riskType?.id,
          ) as string[]
          riskTypes.riskTypeIds.push(
            ...area.risks
              ?.map(risk => risk.riskType.id)
              .filter(id => !existingRiskTypeIds.includes(id)),
          )
          if (riskTypes.riskTypeIds.length > 0)
            return await postRequest(postRisksTypesEndpoint, riskTypes, posting)
        }),
    )

    // create custom areas and risks
    await Promise.all(
      body
        .filter(area => area.isCustom)
        .map(async (area) => {
          const riskTypeIds = {
            riskTypeIds: area.risks?.map((risk: Risk) => risk.riskType.id),
          }
          // if the custom area does not exist before
          if (!area.id) {
            const createdCustomArea = (await postRequest(
              `projects/${selectedProject.value?.id}/custom-areas`,
              { name: area.name },
              posting,
            )) as Area | null
            if (createdCustomArea?.id && riskTypeIds.riskTypeIds?.length) {
              return postRequest(
                `projects/${selectedProject.value?.id}/areas/${createdCustomArea.id}/create-bulk-risks`,
                riskTypeIds,
                posting,
              )
            }
          }
          // if the custom area already exist, filter out the newly added risks
          else {
            const existingRiskTypeIds
              = selectedProject.value?.areas
                ?.find(a => a.id === area.id)
                ?.risks?.map(risk => risk.riskType?.id) ?? []
            riskTypeIds.riskTypeIds
              = riskTypeIds.riskTypeIds?.filter(
                id => !existingRiskTypeIds.includes(id),
              ) ?? []
            if (riskTypeIds.riskTypeIds.length > 0) {
              return postRequest(
                `projects/${selectedProject.value?.id}/areas/${area.id}/create-bulk-risks`,
                riskTypeIds,
                posting,
              )
            }
          }
        }),
    )

    showNotification.value = true
    posting.value = false
  }

  async function patchRisk(patching: Ref<boolean>, body?: any, id?: number) {
    const endpoint = `projects/${selectedProject.value?.id}/areas/${selectedArea.value?.id}/risks/${id ?? selectedRisk.value?.id}`
    patching.value = true

    await patchRequest(endpoint, body ?? selectedRisk.value, patching)

    showNotification.value = true
    patching.value = false
  }

  async function deleteRisk(deleting: Ref<boolean>, id?: string) {
    const projectId = selectedProject.value?.id
    const areaId = selectedArea.value?.id
    const riskId = id ?? selectedRisk.value?.id

    if (!projectId || !areaId || !riskId) {
      console.error('One or more required IDs are missing')
      return
    }

    const endpoint = `projects/${projectId}/areas/${areaId}/risks/${riskId}`
    deleting.value = true

    await deleteRequest(endpoint, deleting)

    showNotification.value = true
    deleting.value = false
  }

  async function deleteArea(deleting: Ref<boolean>, id: string) {
    const projectId = selectedProject.value?.id
    if (!projectId || !id) {
      console.error('Project ID or Area ID is missing')
      return
    }

    const endpoint = `projects/${projectId}/areas/${id}`
    deleting.value = true

    await deleteRequest(endpoint, deleting)

    showNotification.value = true
    deleting.value = false
  }

  async function patchScenario(patching: Ref<boolean>, body: any, id?: number) {
    const endpoint = `projects/${selectedProject.value?.id}/areas/${selectedArea.value?.id}/risks/${selectedRisk.value?.id}/scenarios/${id ?? selectedScenario.value?.id}`
    patching.value = true

    await patchRequest(endpoint, body, patching)

    showNotification.value = true
    patching.value = false
  }

  async function deleteScenario(deleting: Ref<boolean>, id?: number) {
    const endpoint = `projects/${selectedProject.value?.id}/areas/${selectedArea.value?.id}/risks/${selectedRisk.value?.id}/scenarios/${id}`
    deleting.value = true

    await deleteRequest(endpoint, deleting)

    showNotification.value = true
    deleting.value = false
  }

  async function fetchAllSolutionTypes(fetching: Ref<boolean>) {
    if (allSolutionTypes.value.length > 0)
      return

    const fetchEndpoint = 'solution-types'

    const data = ((await fetchRequest(fetchEndpoint, fetching))
      ?? []) as SolutionType[]
    allSolutionTypes.value = data
  }

  async function uploadScenarioImages(posting: Ref<boolean>, file: File) {
    const projectId = selectedProject.value?.id
    const areaId = selectedArea.value?.id
    const riskId = selectedRisk.value?.id
    const scenarioId = selectedScenario.value?.id

    if (!projectId || !areaId || !riskId || !scenarioId) {
      console.error('One or more required IDs are missing')
      return
    }

    const endpoint = `projects/${projectId}/areas/${areaId}/risks/${riskId}/scenarios/${scenarioId}/images`

    const formData = new FormData()
    formData.append('image', file)
    const createdImage = (await postRequest(
      endpoint,
      formData,
      posting,
    )) as ScenarioImage | null

    // put images into store
    if (createdImage) {
      const newFile = new File([file], `${createdImage.id}.jpg`, {
        type: file.type,
      })
      if (scenarioImages.value.has(scenarioId)) {
        scenarioImages.value?.get(scenarioId)?.push(newFile)
      }
      else {
        scenarioImages.value.set(scenarioId, [newFile])
      }
    }
    return createdImage
  }

  async function loadScenarioImages(fetching: Ref<boolean>) {
    const projectId = selectedProject.value?.id
    const areaId = selectedArea.value?.id
    const riskId = selectedRisk.value?.id
    const scenarioId = selectedScenario.value?.id

    if (!projectId || !areaId || !riskId || !scenarioId) {
      console.error('One or more required IDs are missing')
      return
    }

    if (
      !selectedScenario.value?.images
      || selectedScenario.value?.images.length === 0
    ) {
      return
    }

    if (
      !scenarioImages.value.has(scenarioId)
      || scenarioImages.value.get(scenarioId)?.length
      !== selectedScenario.value?.images.length
    ) {
      scenarioImages.value.set(scenarioId, [])
    }
    else {
      return
    }

    await Promise.all(
      selectedScenario.value?.images?.map(async (img) => {
        const imageId = img.id
        const endpoint = `projects/${projectId}/areas/${areaId}/risks/${riskId}/scenarios/${scenarioId}/images/${imageId}`
        const blob = (await fetchRequest(endpoint, fetching)) as Blob
        const fileName = `${imageId}.jpg`

        const file = new File([blob], fileName, { type: blob.type })
        scenarioImages.value.get(scenarioId)?.push(file)
      }),
    )
  }

  async function removeScenarioImage(deleting: Ref<boolean>, imageId?: string) {
    const projectId = selectedProject.value?.id
    const areaId = selectedArea.value?.id
    const riskId = selectedRisk.value?.id
    const scenarioId = selectedScenario.value?.id

    if (!projectId || !areaId || !riskId || !scenarioId || !imageId) {
      console.error('One or more required IDs are missing')
      return
    }

    const endpoint = `projects/${projectId}/areas/${areaId}/risks/${riskId}/scenarios/${scenarioId}/images/${imageId}`

    await deleteRequest(endpoint, deleting)
  }

  async function createCustomScenarios(
    posting: Ref<boolean>,
    customScenarios: string[],
  ) {
    const projectId = selectedProject.value?.id
    const areaId = selectedArea.value?.id
    const riskId = selectedRisk.value?.id

    if (!projectId || !areaId || !riskId) {
      console.error('One or more required IDs are missing')
      return
    }

    if (!customScenarios || customScenarios.length === 0) {
      return
    }

    await Promise.all(
      customScenarios.map(async (name) => {
        const endpoint = `projects/${projectId}/areas/${areaId}/risks/${riskId}/custom-scenarios`
        return await postRequest(endpoint, { name }, posting)
      }),
    )
  }

  async function createCustomSolution(posting: Ref<boolean>, name: string) {
    const projectId = selectedProject.value?.id
    const areaId = selectedArea.value?.id
    const riskId = selectedRisk.value?.id
    const endpoint = `projects/${projectId}/areas/${areaId}/risks/${riskId}/scenarios/${selectedScenario.value?.id}/custom-solutions`
    return await postRequest(endpoint, { name }, posting)
  }

  return {
    projectTitle,
    accountSites,
    riskValueChains,
    tempCoverImage,
    status,
    opportunities,
    selectedArea,
    selectedRisk,
    selectedProject,
    selectedScenario,
    selectedOpportunity,
    selectedOpportunityContactPerson,
    selectedAccountSite,
    selectedRiskValueChain,
    selectedSortBy,
    draftProjects,
    inProgressProjects,
    completedProjects,
    suggestedAreas,
    suggestedRisks,
    allRiskTypes,
    suggestedSolutionTypes,
    projects,
    pending,
    error,
    customError,
    showNotification,
    shouldRefetch,
    reset,
    resetImage,
    resetNewProject,
    setImage,
    fetchProjects,
    deleteProject,
    fetchOpportunities,
    fetchContactPerson,
    fetchAccountAccountSites,
    fetchRiskValueChains,
    fetchSuggestedAreas,
    fetchSuggestedRiskTypes,
    fetchAllRiskTypes,
    fetchSuggestedSolutionTypes,
    createAreasAndRisks,
    patchRisk,
    patchScenario,
    deleteScenario,
    createProject,
    updateProject,
    fetchRequest,
    postRequest,
    patchRequest,
    deleteRequest,
    deleteSolution,
    createBulkSolution,
    cache,
    uploadScenarioImages,
    scenarioImages,
    loadScenarioImages,
    removeScenarioImage,
    fetchAllSolutionTypes,
    createCustomScenarios,
    deleteRisk,
    deleteArea,
    allSolutionTypes,
    createCustomSolution,
    customSuccessMessage,
  }
})
